class InView {
  constructor() {
    this.elements = [];

    setTimeout(() => {
      this.onScroll();
    }, 200);
  }

  onScroll() {
    // eslint-disable-next-line no-undef
    this.elements.forEach((el) => {
      if (el.element.getBoundingClientRect().top - window.innerHeight / 1.5 < 0) {
        el.cb();
      }
    });
  }

  watch(element, cb = () => {}) {
    this.elements.push({
      element,
      cb,
    });
  }
}

const inView = new InView();

// eslint-disable-next-line import/prefer-default-export
export {inView};
