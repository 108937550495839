// Libs
import Plyr from 'plyr';
import 'bootstrap';
import {inView} from "./utils";

window.bootstrap = require('bootstrap');

const players = Plyr.setup('[data-plyr-config]');

const navbar = document.querySelector('.Navbar');

let megaClose; let megaOpen;
let isMegaOpen = false;

if (navbar) {
  const megaLink = navbar.querySelector('.Navbar-link--mega');
  const mega = navbar.querySelector('.Navbar-mega');
  const megaCloseLink = navbar.querySelector('.Navbar-megaClose');

  const navToggle = navbar.querySelector('.Navbar-toggle');

  if (navToggle) {
    navToggle.addEventListener('click', (e) => {
      e.preventDefault();

      navbar.classList.toggle('Navbar--open');

      if (!navbar.classList.contains('Navbar--open')) {
        megaClose(e);
      }

      document.body.classList.toggle('mobile-nav-open');
    })
  }

  let megaLeaveTimeout = null;

  if (megaLink && mega) {
    let leaveTime = 500;
    let hasClickListener = false;
    let hasMouseListener = false;

    const bindNavListeners = () => {
      const ww = window.innerWidth;
      if (ww < 991) {
        leaveTime = 0;

        if (!hasClickListener) {
          hasClickListener = true;
          hasMouseListener = false;
          megaLink.addEventListener('click', megaOpen);
          megaCloseLink.addEventListener('click', megaClose);

          megaLink.removeEventListener('mouseenter', megaOpen);
          megaLink.removeEventListener('mouseleave', megaClose);
          mega.removeEventListener('mouseenter', megaOpen);
          mega.removeEventListener('mouseleave', megaClose)
        }
      } else {
        leaveTime = 500;

        if (!hasMouseListener) {
          hasClickListener = false;
          hasMouseListener = true;

          megaLink.removeEventListener('click', megaOpen);
          megaCloseLink.removeEventListener('click', megaClose);

          megaLink.addEventListener('mouseenter', megaOpen);
          megaLink.addEventListener('mouseleave', megaClose)
          mega.addEventListener('mouseenter', megaOpen);
          mega.addEventListener('mouseleave', megaClose);
        }
      }
    }

    megaOpen = (e) => {
      e.preventDefault();
      clearTimeout(megaLeaveTimeout);
      mega.classList.add('Navbar-mega--open');
      navbar.classList.add('Navbar--scrolled');
      isMegaOpen = true;
    }

    megaClose = (e) => {
      e.preventDefault();
      clearTimeout(megaLeaveTimeout);
      megaLeaveTimeout = setTimeout(() => {
        mega.classList.remove('Navbar-mega--open');
        if (window.scrollY > 100) {
          navbar.classList.add('Navbar--scrolled');
        } else {
          navbar.classList.remove('Navbar--scrolled');
        }
        isMegaOpen = false;
      }, leaveTime);
    }

    window.addEventListener('resize', bindNavListeners);
    bindNavListeners();
  }
}

/* Contact form modal */
const contactModalEl = document.getElementById('inquiryForm');
if (contactModalEl) {
  const contactModal = new bootstrap.Modal(contactModalEl);
  const contactFormTriggers = document.querySelectorAll('[href="#form"]');

  if (contactFormTriggers && contactModal) {
    contactFormTriggers.forEach((trigger) => {
      trigger.addEventListener('click', (e) => {
        e.preventDefault();
        contactModalEl.querySelector('[name="predmet"]').value = trigger.getAttribute('title');
        contactModal.show();
      });
    });
  }
}

/* JOB form modal */
const jobFormEl = document.getElementById('jobForm');
if (jobFormEl) {
  const jobForm = new bootstrap.Modal(jobFormEl);
  const jobFormTriggers = document.querySelectorAll('[href="#jobForm"]');

  if (jobFormTriggers && jobForm) {
    jobFormTriggers.forEach((trigger) => {
      trigger.addEventListener('click', (e) => {
        e.preventDefault();
        // jobFormEl.querySelector('[name="predmet"]').value = trigger.getAttribute('title');
        jobForm.show();
      });
    });
  }
}

function getQueryVariable(variable) {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) == variable) {
      return decodeURIComponent(pair[1]);
    }
  }
}

const formId = getQueryVariable('form');
const success = getQueryVariable('success');

const url = new URL(window.location.href);
if (formId && success === '1') {
  const successModal = new bootstrap.Modal(`#${formId}Success`);
  successModal.show();
  window.history.replaceState(null, '', `${url.protocol}//${url.host}${url.pathname}`);
} else if (formId) {
  const formModal = new bootstrap.Modal(`#${formId}`);
  formModal.show();
  window.history.replaceState(null, '', `${url.protocol}//${url.host}${url.pathname}`);
}

const fileInputs = document.querySelectorAll('[type="file"]');

fileInputs.forEach((input) => {
  input.addEventListener('change', (e) => {
    const textWrapper = input.parentElement.querySelector('.Form-inputValue');

    if (e.target.value.length) {
      input.parentElement.classList.add('hasFile');

      const lastSlash = Math.max(e.target.value.lastIndexOf('/'), e.target.value.lastIndexOf('\\'));
      if (lastSlash) {
        textWrapper.innerText = e.target.value.substring(lastSlash + 1);
      } else {
        textWrapper.innerText = e.target.value;
      }
    } else {
      input.parentElement.classList.remove('hasFile');
      textWrapper.innerText = '';
    }
  });
});


const pageProtect = document.getElementById('pageProtect');
if (pageProtect) {
  const parent = pageProtect.parentElement;
  const blocks = document.querySelectorAll('[id^="blok-"]');

  if (parent) {
    blocks.forEach((block) => {
      if (block.id == parent.id) {
        // do nothign
      } else {
        block.style.visibility = 'hidden';
      }
    });
  }

  const pageProtectModal = new bootstrap.Modal(pageProtect);
  pageProtectModal.show();

  const acceptButton = pageProtect.querySelector('#page-protect-accept');
  if (acceptButton) {
    acceptButton.addEventListener('click', (e) => {
      e.preventDefault();
      pageProtectModal.hide();
      blocks.forEach((block) => {
        block.style.visibility = 'visible';
      });
    })
  }
}


const sections = document.querySelectorAll('section:not(.MainHeader), .Cta, .MainHeader-wrapper');

if (sections) {
  sections.forEach((section) => {
    inView.watch(section, () => {
      section.classList.add('inView');
    })
  });
}

const mainElement = document.querySelector('main');
const isHome = document.body.classList.contains('home');
window.addEventListener('scroll', () => {
  const modifier = 5;
  const st = window.scrollY;
  const bgScroll = st / modifier;

  let offset = 0;

  if (isHome) {
    offset = window.innerHeight * .75;
  }

  mainElement.style.backgroundPosition = `center ${offset + bgScroll}px`;

  if (st > 100) {
    navbar.classList.add('Navbar--scrolled');
  } else if (st < 100 && !isMegaOpen) {
    navbar.classList.remove('Navbar--scrolled');
  }

  if (st > 100) {
    navbar.classList.add('Navbar--scrolled');
  } else {
    navbar.classList.remove('Navbar--scrolled');
  }

  inView.onScroll();
});

/* pause videos on modal close */
const allModals = document.querySelectorAll('.modal');
allModals.forEach((modal) => {
  modal.addEventListener('hide.bs.modal', () => {
    if (Array.isArray(players)) {
      players.forEach((player)=> {
        player.pause();
      });
    }
  })
})

const cookieLink = document.querySelector('[href="#showCookies"]');

if (cookieLink) {
  cookieLink.addEventListener('click', (e) => {
    e.preventDefault();

    cookieconsent.showSettings();
  });
}
